import { withScope } from "@sentry/nextjs"

export const AUTH = "AUTH"

export function createAuth(user, token) {
  return (dispatch) => {
    withScope((scope) => {
      scope.setUser({ id: user.id })
    })
    localStorage.setItem("token", token)
    localStorage.setItem("user", JSON.stringify(user))
    dispatch({
      type: AUTH,
      payload: { user, token },
    })
  }
}

export function logout() {
  withScope((scope) => {
    scope.setUser({ id: null })
  })
  const buildTime = localStorage.getItem("buildTime")
  const persistRoot = JSON.parse(localStorage.getItem("persist:root") || "{}")
  const currentLanguageCode = persistRoot.currentLanguageCode || ""
  localStorage.clear()
  localStorage.setItem("buildTime", buildTime)
  localStorage.setItem("persist:root", JSON.stringify({ currentLanguageCode }))

  return {
    type: AUTH,
    payload: null,
  }
}
