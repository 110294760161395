import { useState, useRef, useEffect } from "react"
import { useSelector } from "react-redux"
import { enqueueSnackbar } from "notistack"
import Button from "@mui/material/Button"
import NiceModal from "@ebay/nice-modal-react"

import useTranslation from "../hooks/use_translation"
import { useReduxState } from "../hooks/use_redux_state"
import LanguageSwitcherModal from "./language_switcher_modal"
import Box from "@mui/material/Box"

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  pickerContainer: {
    height: "150px",
    width: "100%",
    overflowY: "scroll",
  },
  pickerItem: {
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
    color: "#555",
    cursor: "pointer",
    transition: "box-shadow ease .2s",
  },
  selectedItem: {
    color: "black",
    boxShadow: "inset 0 1px 0 lightgray, inset 0 -1px 0 lightgray",
    color: "#000",
  },
}

const LanguagesPicker = () => {
  const languages = useSelector((state) => state.configuration.languages)
  const currentLanguageCode = useSelector((state) => state.currentLanguageCode)
  const [, setCurrentLanguageCode] = useReduxState({
    key: "currentLanguageCode",
  })
  const { t } = useTranslation()

  const options = languages.map((lng) => ({
    ...lng,
    id: lng.code,
    label: t(lng.code),
  }))

  let defaultOptionSelected

  if (options.length === 0) {
    defaultOptionSelected = null
  } else if (currentLanguageCode) {
    defaultOptionSelected = currentLanguageCode
  } else {
    defaultOptionSelected = options[0].id
  }

  const [selectedOptionId, setSelectedOptionId] = useState(
    defaultOptionSelected
  )
  const containerRef = useRef(null)
  const itemHeight = 40

  const scrollToOption = (selectedOptionId) => {
    const index = options.findIndex((option) => option.id === selectedOptionId)
    containerRef.current.scrollTo({
      top: index * itemHeight,
      behavior: "smooth",
    })
    setSelectedOptionId(selectedOptionId)
  }

  const handleScroll = () => {
    const index = Math.round(containerRef.current.scrollTop / itemHeight)
    const selectedIndex = options.findIndex(
      (option) => option.id === selectedOptionId
    )
    if (index !== selectedIndex) {
      setSelectedOptionId(options[index].id)
    }
  }

  const handleClick = (id) => {
    scrollToOption(id)
  }

  useEffect(() => {
    scrollToOption(selectedOptionId)
  }, [])

  const onSubmit = (selectedOptionId) => {
    setCurrentLanguageCode(selectedOptionId)
    enqueueSnackbar(t("lagnuageHasBeenChanges"), { variant: "success" })
    NiceModal.hide(LanguageSwitcherModal)
  }

  return (
    <div>
      <Box sx={styles.container}>
        <Box
          ref={containerRef}
          onScroll={handleScroll}
          sx={styles.pickerContainer}
        >
          {options.map((option) => (
            <Box
              key={option.id}
              onClick={() => handleClick(option.id)}
              sx={{
                ...styles.pickerItem,
                ...(option.id === selectedOptionId ? styles.selectedItem : {}),
              }}
            >
              {option.label}
            </Box>
          ))}
        </Box>
      </Box>
      <Button
        onClick={() => {
          onSubmit(selectedOptionId)
        }}
        variant="contained"
        color="primary"
      >
        {t("save")}
      </Button>
    </div>
  )
}

export default LanguagesPicker
