import { styled } from "@mui/system"

const Hr = styled("hr")({
  border: 0,
  clear: "both",
  display: "block",
  backgroundColor: "WhiteSmoke",
  height: "1px",
  width: "100%",
  marginTop: 0,
  marginBottom: 0,
})

export default Hr
