import { Desktop, Mobile } from "@/components/device"
import { useSelector } from "react-redux"

const styles = {
  mobile: {
    height: 35,
  },
  desktop: {
    height: 55,
  },
}

const AppLogo = ({ ...props }) => {
  let logoUrl = useSelector((s) => s.configuration?.logoUrl)
  const signupCohort = useSelector((s) => s.signupCohort)
  if (signupCohort?.logoUrl) {
    logoUrl = signupCohort.logoUrl
  }
  return (
    <>
      {logoUrl && (
        <>
          <Mobile>
            <img src={logoUrl.slice(11)} style={styles.mobile} alt="logo" {...props} />
          </Mobile>
          <Desktop>
            <img src={logoUrl.slice(11)} style={styles.desktop} alt="logo" {...props} />
          </Desktop>
        </>
      )}
    </>
  )
}

export default AppLogo
