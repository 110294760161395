import AppBar from "@mui/material/AppBar"
import { Helmet } from "react-helmet"

import AppHeaderSide1 from "./app_header_side_1"
import AppHeaderSide2 from "./app_header_side_2"
import AppHeaderCenter from "./app_header_center"
import useTranslation from "../hooks/use_translation"

const styles = {
  header: (theme) => ({
    width: "100%",
    backgroundColor: "#fff",
    boxShadow: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    [theme.breakpoints.up("desktop")]: {
      padding: "10px 70px",
    },
    [theme.breakpoints.down("desktop")]: {
      padding: "5px",
    },
  }),
}

const AppHeader = () => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{t("projectName")}</title>
      </Helmet>
      <AppBar position="static" sx={styles.header} color="default">
        <AppHeaderSide1 />
        <AppHeaderCenter />
        <AppHeaderSide2 />
      </AppBar>
    </>
  )
}

AppHeader.propTypes = {}

export default AppHeader
