import LanguageIcon from "@mui/icons-material/Language"
import IconButton from "@mui/material/IconButton"
import { useSelector } from "react-redux"
import NiceModal from "@ebay/nice-modal-react"

import { Desktop } from "../components/device"
import LanguageSwitcherModal from "./language_switcher_modal"

const styles = {
  button: {
    padding: "3px",
  },
  icon: {
    fontSize: "30px",
  },
  menuItem: {
    display: "flex",
    flexDirection: "column",
    minHeight: "min-content",
    alignItems: "flex-start",
  },
}

const ChangeLanguageButton = () => {
  const languages = useSelector((state) => state.configuration?.languages)
  const portalMultiLanguages = languages?.length > 1

  if (!portalMultiLanguages) {
    return <></>
  }

  const changeLanguage = () => {
    NiceModal.show(LanguageSwitcherModal)
  }

  return (
    <Desktop>
      <IconButton sx={styles.button} onClick={changeLanguage}>
        <LanguageIcon sx={styles.icon} />
      </IconButton>
    </Desktop>
  )
}

export default ChangeLanguageButton
