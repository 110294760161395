import colors from "@/utils/colors"
import { styled } from "@mui/system"
import Link from "next/link"

const HelpLink = styled(Link)({
  fontSize: "18px",
  color: colors.brandAqua,
  fontWeight: "700",
  textDecoration: "none",
  display: "flex",
  "&:hover span": { textDecoration: "underline" },
  "& svg": { marginRight: 10 },
})

export default HelpLink
