"use client"

import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

import { persistor, store } from "@/lib/store"
import useLatestBuild from "@/hooks/use_latest_build"

export default function StoreProvider({ children }) {
  useLatestBuild("StoreProvider")
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  )
}
