import { useCallback, useEffect, useRef } from "react"

const wait = async (ms) => new Promise(resolve => { setTimeout(resolve, ms) })

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const useAwaitingInterval = (asyncCallback, ms) => {
  const isCanceled = useRef(false)
  const loop = useCallback(async () => {
    if (!isCanceled.current) { await wait(ms) }
    if (!isCanceled.current) { await asyncCallback() }
    if (!isCanceled.current) { await loop() }
  }, [asyncCallback, ms, isCanceled])
  useEffect(() => {
    loop()
    return () => isCanceled.current = true
  }, [loop])
}
