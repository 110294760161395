import { POST, GET, DELETE, action } from "."
import { API_ROOT_URL } from "../requests"
import axios from "axios"
import isEqual from "lodash-es/isEqual"

import { fetchParticipant, getUser } from "../requests/user"
import { createAuth } from "./auth"

export const FETCH_USERS = "FETCH_USERS"
export const ADD_USER = "ADD_USER"
export const EDIT_USER = "EDIT_USER"
export const DELETE_USER = "DELETE_USER"

export async function editUser(details) {
  await axios.post(`${API_ROOT_URL}/protected/participant/details`, details)
}

export const updateUserInfo = () => async (dispatch, getState) => {
  const { auth } = getState()
  const participant = await fetchParticipant()
  if(!isEqual(participant, auth.user)) {
    dispatch(createAuth(participant, auth.token))
  }
}
