import { useEffect } from "react"

import { useFetch } from "./use_fetch"
import { useReduxState } from "./use_redux_state"
import { useSelector } from "react-redux"

const useConfiguration = () => {
  const hasAuth = useSelector((state) => Boolean(state.auth?.user))
  const path = `/${hasAuth ? "protected" : "public"}/configuration`
  const [configuration, isLoading, error] = useFetch(path, { useCache: !hasAuth })
  const [, setConfiguration] = useReduxState({ key: "configuration" })

  useEffect(() => {
    setConfiguration(configuration)
  }, [configuration, setConfiguration])

  return [configuration, isLoading, error]
}

export default useConfiguration
