const colors = {
  statesErrorTertiary: "#F9E1E6",
  statesErrorTertiary60: "rgba(249, 225, 230, 0.6)",
  statesErrorSecondary: "#C57282",
  statesErrorPrimary: "#ED4434",
  statesErrorPrimary50: "rgba(237, 68, 52, 0.5)",
  statesSuccessTertiary40: "rgba(193, 239, 201, 0.4)",
  statesSuccessTertiary: "#C1EFC9",
  statesSuccessSecondary: "#7EB694",
  statesSuccessPrimary: "#3ABD66",
  brandBlue: "#4a90e2",
  brandBlue20: "rgba(74, 144, 226, 0.2)",
  brandBlue50: "rgba(74, 144, 226, 0.5)",
  brandAqua: "#33E6EE",
  brandLightBlue: "#c8ebf2",
  brandLightBlue20: "rgba(200, 235, 242, 0.2)",
  brandLightBlue40: "rgba(200, 235, 242, 0.4)",
  brandLightBlue60: "rgba(200, 235, 242, 0.6)",
  brandLightGreen: "#c1efe0",
  brandLightYellow: "#fffedc",
  steelBlue: "#21899D",
  yellow: "#764400",
  green: "#092215",
  gray: "rgba(100, 100, 111, 0.2)",
  gray03: "rgba(0, 0, 0, 0.03)",
  gray05: "rgba(0, 0, 0, 0.05)",
  gray10: "rgba(0, 0, 0, 0.1)",
  gray20: "rgba(0, 0, 0, 0.2)",
  gray30: "rgba(0, 0, 0, 0.3)",
  gray40: "rgba(0, 0, 0, 0.4)",
  gray50: "rgba(0, 0, 0, 0.5)",
  gray60: "rgba(0, 0, 0, 0.6)",
  gray65: "rgba(0, 0, 0, 0.65)",
  gray80: "rgba(0, 0, 0, 0.8)",
  gray100: "rgba(0, 0, 0, 1)",
}

export default colors
