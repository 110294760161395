import { useDispatch, useSelector } from "react-redux"
import HelpIcon from "@mui/icons-material/Help"
import MuiMenu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ArrowDropDown from "@mui/icons-material/ArrowDropDown"
import IconMenu from "@mui/icons-material/Menu"
import IconButton from "@mui/material/IconButton"
import NiceModal from "@ebay/nice-modal-react"

import { Mobile, Desktop } from "../components/device"
import { logout } from "../actions/auth"
import ErrorBoundary from "../utils/error_boundary"
import useTranslation from "../hooks/use_translation"
import UserConfirmDialog from "../molecules/user_confirm_dialog"
import Hr from "../atoms/hr"
import { useState } from "react"
import LanguageSwitcherModal from "../molecules/language_switcher_modal"
import { useReduxState } from "../hooks/use_redux_state"
import { useSupportUrl } from "../hooks/use_support_url"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import { useRouter } from "next/navigation"

const styles = {
  root: { width: "100%" },
  indicator: { width: "5px" },
  arrowDropDown: { fill: "#bbb" },
  menuItem: {
    display: "flex",
    flexDirection: "column",
    minHeight: "min-content",
    alignItems: "flex-start",
  },
  linkMenuItem: {
    marginLeft: "5px",
    "&:link,&:visited": {
      color: "inherit",
      textDecoration: "inherit",
    },
  },
  menuUser: {
    display: "flex",
    flexDirection: "column",
    minHeight: "min-content",
    alignItems: "flex-start",
    padding: "11px 33px 11px 11px",
    "&:focus": { outline: "none" },
  },
  userNameText: {
    fontSize: "18px",
    color: "#777",
    fontWeight: "700",
  },
  userPhoneText: {
    fontSize: "16px",
    color: "#ccc",
    fontWeight: "300",
  },
  help: {
    display: "flex",
    flexDirection: "row",
  },
}

const Menu = () => {
  const { t } = useTranslation()
  const [currentLanguageCode, setCurrentLanguageCode] = useReduxState({
    key: "currentLanguageCode",
  })
  const configuration = useSelector((state) => state.configuration)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const router = useRouter()

  const dispatch = useDispatch()
  const supportUrl = useSupportUrl()
  const user = useSelector((s) => s.auth?.user)
  const hasAuth = Boolean(user)
  const groups = user?.groups || {}
  const portalMultiLanguages = configuration?.languages?.length > 1
  const reportsEnabled = configuration?.reportsEnabled

  const closeLogoutDialog = () => {
    setIsDialogOpen(false)
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  const closeDialog = () => {
    setIsDialogOpen(false)
  }

  const handleMenu = (event) => {
    setIsMenuOpen(true)
    setAnchorEl(event.currentTarget)
  }

  const onClick = () => {
    closeLogoutDialog()
    dispatch(logout())
    setCurrentLanguageCode(currentLanguageCode)
    gtag("event", "user_logout")
  }

  const handleMenuItem = (event) => {
    const value = event.currentTarget.getAttribute("value")
    if (value === "logout") {
      closeMenu()
      setIsDialogOpen(true)
      return
    }
    if (value === "language") {
      closeMenu()
      NiceModal.show(LanguageSwitcherModal)
      return
    }
    router.push(`/${value}/`)
    closeMenu()
  }

  const userName = `${user?.details?.firstName} ${user?.details?.lastName}`

  return (
    <ErrorBoundary>
      <IconButton
        aria-owns={isMenuOpen ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Desktop>
          <ArrowDropDown sx={styles.arrowDropDown} automation-name="menu-icon" />
        </Desktop>
        <Mobile>
          <IconMenu />
        </Mobile>
      </IconButton>
      <MuiMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        anchorPosition={{ top: 200, left: 800 }}
        getcontentanchorel={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        {hasAuth && (
          <Box sx={styles.menuUser}>
            <Box sx={styles.userNameText}>{userName}</Box>
            <Box sx={styles.userPhoneText}>{user.details?.phone}</Box>
            <Box sx={styles.userPhoneText}>{user.id}</Box>
          </Box>
        )}
        {hasAuth && <Hr />}
        <Mobile>
          <MenuItem sx={styles.menuItem} onClick={closeMenu}>
            <Box sx={[styles.menuItem, styles.help]}>
              <HelpIcon sx={styles.helpIcon} />
              <Link
                rel="noopener noreferrer"
                target="_blank"
                href={supportUrl}
                sx={{ ...styles.linkMenuItem, ...styles.menuItem }}
              >
                {t("help")}
              </Link>
            </Box>
          </MenuItem>
          {portalMultiLanguages && (
            <MenuItem
              automation-name="menu-item-language"
              sx={styles.menuItem}
              value="language"
              onClick={handleMenuItem}
            >
              {t("changeLanguage")}
            </MenuItem>
          )}
        </Mobile>
        {!hasAuth && (
          <MenuItem automation-name="menu-item-signup" sx={styles.menuItem} value="signup" onClick={handleMenuItem}>
            {t("signup")}
          </MenuItem>
        )}
        {!hasAuth && (
          <MenuItem automation-name="menu-item-login" sx={styles.menuItem} value="login" onClick={handleMenuItem}>
            {t("login")}
          </MenuItem>
        )}
        {hasAuth && (
          <MenuItem automation-name="menu-item-home" sx={styles.menuItem} value="home" onClick={handleMenuItem}>
            {t("home")}
          </MenuItem>
        )}
        {hasAuth && reportsEnabled && (
          <MenuItem
            sx={styles.menuItem}
            value="user-reports"
            onClick={handleMenuItem}
            automation-name="menu-item-reports"
          >
            {t("myReports")}
          </MenuItem>
        )}
        {hasAuth && (
          <MenuItem
            automation-name="menu-item-edit-details"
            sx={styles.menuItem}
            value="edit-details"
            onClick={handleMenuItem}
          >
            {t("updateDetails")}
          </MenuItem>
        )}
        {hasAuth && (
          <MenuItem sx={styles.menuItem} value="logout" onClick={handleMenuItem} automation-name="logout">
            {t("logout")}
          </MenuItem>
        )}
      </MuiMenu>
      <UserConfirmDialog
        isDialogOpen={isDialogOpen}
        title={t("logout")}
        body={t("areYouSureYouWantToLeave")}
        textOk={t("textButtonYesIWantToLogOut")}
        textCancel={t("imStaying")}
        onClick={onClick}
        onClose={closeDialog}
      />
    </ErrorBoundary>
  )
}

export default Menu
