import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"

import PropTypes from "prop-types"

const UserConfirmDialog = ({
  isDialogOpen,
  onClick,
  onClose,
  title,
  body,
  textOk,
  textCancel,
}) => (
  <Dialog
    open={isDialogOpen}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {body}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        <span automation-name="close-dialog">{textCancel}</span>
      </Button>
      <Button onClick={onClick} color="primary">
        <span automation-name="confirm">{textOk}</span>
      </Button>
    </DialogActions>
  </Dialog>
)

UserConfirmDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  textCancel: PropTypes.string.isRequired,
  textOk: PropTypes.string.isRequired,
}

export default UserConfirmDialog
