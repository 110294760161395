import React from "react";

import useTranslation from "../hooks/use_translation";
import { captureException } from "@sentry/nextjs";

const ErrorBoundaryText = ({ error }) => {
  const { t } = useTranslation();
  captureException(error);
  console.error(error);
  console.error(`Request Error: ${error} ${error?.response}`);
  return <span>{t("unexpectedError")}</span>;
};

export default ErrorBoundaryText;
