import(/* webpackMode: "eager" */ "/home/runner/work/portal/portal/frontend/web/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/portal/portal/frontend/web/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/portal/portal/frontend/web/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/portal/portal/frontend/web/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/portal/portal/frontend/web/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Alef\",\"arguments\":[{\"subsets\":[\"hebrew\",\"latin\"],\"weight\":[\"400\",\"700\"]}],\"variableName\":\"alef\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/portal/portal/frontend/web/src/app/app.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/portal/portal/frontend/web/src/app/store_provider.jsx");
