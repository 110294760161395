import { If, Else, Then } from "react-if"
import NiceModal, { useModal, muiDialog } from "@ebay/nice-modal-react"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import CircularProgress from "@mui/material/CircularProgress"

import useTranslation from "../hooks/use_translation"
import { useSelector } from "react-redux"
import LanguagesPicker from "./languages_picker"

const styles = {
  dialog: {
    "& .MuiDialog-paper": {
      width: "280px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "20px",
  },
  select: {
    width: "100%",
  },
  loading: {
    margin: "26px auto",
  },
}

const LanguageSwitcherModal = NiceModal.create(() => {
  const modal = useModal()
  const configurationLoading = useSelector((state) => !state.configuration)
  const { t } = useTranslation()

  return (
    <Dialog {...muiDialog(modal)} sx={styles.dialog}>
      <DialogTitle>{t("changeLanguage")}</DialogTitle>
      <DialogContent sx={styles.content}>
        <If condition={configurationLoading}>
          <Then>
            <CircularProgress size={60} sx={styles.loading} />
          </Then>
          <Else>
            <LanguagesPicker />
          </Else>
        </If>
      </DialogContent>
    </Dialog>
  )
})

export default LanguageSwitcherModal
