import { AUTH } from "../actions/auth"

export default function (state = null, { type, payload }) {
  switch (type) {
  case AUTH:
    return payload
  default:
    return state
  }
}
