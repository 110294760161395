import React, { Component } from "react"
import { captureException } from "@sentry/nextjs"
import PropTypes from "prop-types"

import ErrorBoundaryText from "../molecules/error_boundary_text"
import AppHeader from "@/organism/app_header"
import Box from "@mui/material/Box"

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 75px)",
    backgroundColor: "#f5f5f5",
  },
}

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    showErrorMessage: PropTypes.bool,
    notifySentry: PropTypes.bool,
  }

  static defaultProps = {
    children: null,
    showErrorMessage: true,
    notifySentry: true,
  }

  state = { hasError: false }

  componentDidCatch(err) {
    this.setState({ hasError: true })
    if (!this.props.notifySentry) {
      console.error(err)
      captureException(err)
    }
  }
  render() {
    if (this.state.hasError) {
      if (this.props.showErrorMessage) {
        return (
          <Box sx={styles.container}>
            <AppHeader />
            <ErrorBoundaryText />
          </Box>
        )
      }
      return <></>
    }
    if (this.props.children) {
      return this.props.children
    }
    return <></>
  }
}

export default ErrorBoundary
