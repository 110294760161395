import axios from "axios";
import humps from "humps";

import { logout } from "@/actions/auth";
import { store } from "@/lib/store"

export const init = () => {
  if (!process.env.NEXT_PUBLIC_PORTAL_BACKEND_URL) {
    console.error("axios init error", process.env.NEXT_PUBLIC_PORTAL_BACKEND_URL);
    alert("PORTAL_BACKEND_URL not defined");
  }

  axios.interceptors.response.use(
    (response) => {
      if (response.data) {
        response.data = humps.camelizeKeys(response.data);
      }
      return response;
    },
    (error) => {
      const { response } = error;
      if (response?.data) {
        error.response.data = humps.camelizeKeys(error.response.data);
      }
      if (error?.config?.url) {
        const url = new URL(error.config.url);
        const isOurBackendResponse = url.origin === process.env.NEXT_PUBLIC_PORTAL_BACKEND_URL
        const requestHadToken = Boolean(error?.config?.headers?.Authorization)
        if (response?.status === 401 && isOurBackendResponse && requestHadToken) {
          console.log(`401 error on ${url.origin}, clearing localStorage`);
          store.dispatch(logout())
        }
      }
      return Promise.reject(error);
    }
  );

  axios.interceptors.request.use((config) => {
    if (config.url?.startsWith(process.env.NEXT_PUBLIC_PORTAL_BACKEND_URL)) {
      if (config.data) {
        config.data = humps.decamelizeKeys(config.data);
      }
      const token = window.localStorage.getItem("token");
      if (token) {
        config.headers = config.headers || {};
        config.headers.Authorization = token;
      }
    }
    return config;
  });
};

export const queryString = (params) => {
  return new URLSearchParams(humps.decamelizeKeys(params)).toString();
};
