import { useEffect } from "react"
import { useInterval } from "./use_interval"
import { useDispatch } from "react-redux"
import { logout } from "@/actions/auth"

const useInactivityCheck = () => {
  const dispatch = useDispatch()

  const verifyLocalStorage = () => {
    try {
      localStorage.setItem("private-browser-detection", 1)
      localStorage.removeItem("private-browser-detection")
      return false
    } catch (e) {
      Storage.prototype._setItem = Storage.prototype.setItem
      Storage.prototype.setItem = function () {}
    }
  }

  const updateLastInteractionTime = () => {
    localStorage.setItem("site_is_running", Date.now().toString())
  }

  useEffect(() => {
    verifyLocalStorage()

    const loginDate = localStorage.getItem("login_date")
    const lastActionTime = parseInt(localStorage.getItem("last_action") || "0", 10)
    const siteIsRunningTime = parseInt(localStorage.getItem("site_is_running") || "0", 10)

    const timeFromLastAction = Date.now() - lastActionTime
    const timeFromSiteIsRunning = Date.now() - siteIsRunningTime

    const everyThingIsOk =
      loginDate === new Date().toDateString() &&
      timeFromLastAction < 60 * 60 * 1000 &&
      timeFromSiteIsRunning < (process.env.NEXT_PUBLIC_ENV === "local" ? 1000 * 60 : 1000 * 5)

    if (!everyThingIsOk) {
      dispatch(logout())
    }
  }, [])

  useInterval(updateLastInteractionTime, 500)
}

export default useInactivityCheck
