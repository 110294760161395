import { useLayoutEffect } from "react"
import { useRouter } from "next/navigation"

const useLatestBuild = (componentName) => {
  const router = useRouter()
  useLayoutEffect(() => {
    const versionBuildTime = process.env.NEXT_PUBLIC_BUILD_TIME
    const localBuildTime = localStorage.getItem("buildTime")
    if (!versionBuildTime) {
      return
    }
    if(localBuildTime == versionBuildTime) {
      return
    }
    console.info(`new build: ${componentName} - local: ${localBuildTime} new: ${versionBuildTime}`)
    localStorage.clear()
    localStorage.setItem("buildTime", versionBuildTime)
    setTimeout(() => {
      router.refresh();
    }, 0);
  }, [])
}

export default useLatestBuild
