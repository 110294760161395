import { useTranslation as useI18Translation } from "react-i18next"
import { useSelector } from "react-redux"
import { usePathname, useSearchParams } from "next/navigation"
import humps from "humps"

const generateKeyWithContext = (i18, key, contexts) => {
  for (const [context, value] of Object.entries(contexts)) {
    if (!value && !Number.isInteger(value)) {
      continue
    }
    const keyWithContext = key + "__" + context + "__" + value
    if (i18.exists(keyWithContext)) {
      return keyWithContext
    }
  }
  return key
}

const injectProjectName = (value, translation, ciabName) => {
  if (!ciabName) {
    return value
  }
  const projectNamePlaceholder = "{{projectName}}"
  if (value.includes(projectNamePlaceholder)) {
    console.log(
      "ciabName",
      ciabName,
      translation.t(`projectName__ciab__${ciabName}`)
    )
    const projectName = translation.t(`projectName__ciab__${ciabName}`)
    value = value.replace(projectNamePlaceholder, projectName)
  }
  return value
}

const useTranslation = () => {
  const translation = useI18Translation()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const ciabName = useSelector((s) => s.configuration?.ciabName)
  const gender = useSelector((s) => s.auth?.user?.details?.gender)

  let cohortSlug = useSelector((s) => s.auth?.user?.cohort?.slug)
  if (pathname?.toLowerCase().startsWith("/signup")) {
    cohortSlug = searchParams.get("cohort")
  }
  let fileType
  if (pathname?.toLowerCase().startsWith("/medical-files") && pathname.split("/")[2]) {
    fileType = humps.camelize(pathname.split("/")[2])
  }

  const defaultContext = {
    gender,
    cohortSlug,
    fileType,
    ciab: ciabName,
  }

  const t = (key, context) => {
    const contextEntries = Object.entries({
      ...defaultContext,
      ...(context || {}),
    })

    const camelizedEntries = contextEntries.map(([key, value]) => {
      if (value && typeof value === "string") {
        return [key, humps.camelize(value)]
      }
      return [key, value]
    })
    const camelizedContext = Object.fromEntries(camelizedEntries)
    const keyWithContext = generateKeyWithContext(
      translation.i18n,
      key,
      camelizedContext
    )

    let value = translation.t(keyWithContext)
    value = injectProjectName(value, translation, ciabName)
    return value
  }
  return { ...translation, t }
}

export default useTranslation
