import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { createAuth } from "../actions/auth"
import { fetchMedications } from "../actions/medications"
import { updateUserInfo } from "../actions/users"

const DataLoader = ({ children }) => {
  const auth = useSelector((s) => s.auth)
  const dispatch = useDispatch()
  const [fetchedData, setFetchedData] = useState(false)

  useEffect(() => {
    const token = window.localStorage.getItem("token")
    if (token) {
      const user = JSON.parse(window.localStorage.getItem("user"))
      dispatch(createAuth(user, token))
    }
  }, [])

  useEffect(() => {
    if (auth && !fetchedData) {
      dispatch(updateUserInfo())
      setFetchedData(true)
    } else if (!auth && fetchedData) {
      setFetchedData(false)
    }
  }, [auth, fetchedData])

  useEffect(() => {
    dispatch(fetchMedications())
  }, [])

  return children
}

DataLoader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default DataLoader
