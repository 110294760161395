import axios from "axios"
import { API_ROOT_URL } from "../requests"

export const fetchMedications = () => {
  return async (dispatch) => {
    try {
      const url = `${API_ROOT_URL}/public/medications`
      const { data: medications } = await axios.get(url)
      dispatch({ type: "medications", payload: medications })
    } catch (error) {
      console.error("Error fetching medications:", error)
      dispatch({ type: "FETCH_MEDICATIONS_ERROR", payload: error })
    }
  }
}

export const filterMedications = (filteredMedications) => {
  return { type: "filteredMedications", payload: filteredMedications }
}
