import Box from "@mui/material/Box"
import Link from "next/link"

import { Desktop } from "../components/device"
import AppLogo from "./app_logo"

const styles = {
  logos: {
    display: "flex",
    alignItems: "center",
    gap: "25px",
    fontSize: 0,
  },
  link: {
    display: "block",
    height: "55px",
  },
}

const AppHeaderSide1 = () => {
  return (
    <Box className={styles.logos}>
      <Desktop>
        <Link
          rel="noopener noreferrer"
          href="/"
          style={styles.link}
        >
          <AppLogo />
        </Link>
      </Desktop>
    </Box>
  )
}

AppHeaderSide1.propTypes = {}

export default AppHeaderSide1
