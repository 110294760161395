import { Mobile } from "../components/device"
import AppLogo from "./app_logo"

const styles = {
  logo: {
    height: 35,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}

const AppHeaderCenter = () => {
  return (
    <Mobile>
      <AppLogo sx={styles.logo} />
    </Mobile>
  )
}

export default AppHeaderCenter
